/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ColumnTypeValues } from './columnTypeValues';
import { ColumnRoleValue } from './columnRoleValue';


export interface ExpColCreateDto { 
    projectId?: string;
    name?: string | null;
    role?: ColumnRoleValue;
    type?: ColumnTypeValues;
    precision?: number | null;
    notes?: string | null;
}
export namespace ExpColCreateDto {
}


