import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Role } from '../../core/models/role.enum';
import { AuthService } from '../../core/services/auth.service';
import { ConfigurationSelectors } from '../../store/configuration/configuration.selectors';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NzFlexModule,
    NzCardModule,
    NzIconModule,
    NzInputModule,
    NzFormModule,
    NzButtonModule,
    NzToolTipModule,
    NzSpinModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  store = inject(Store);
  loading = signal(false);
  authService = inject(AuthService);
  validateForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
  }> = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private router: Router,
    private notificationService: NzNotificationService,
  ) {}

  submitForm() {
    if (this.validateForm.valid) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.loading.set(true);
      this.authService.login(this.validateForm.value as any).subscribe({
        next: () => {
          const user = this.store.selectSnapshot(ConfigurationSelectors.user);
          if (user?.user?.role === Role.SuperAdmin) {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigate(['/']);
          }
        },
        error: (error) => {
          console.log(error);
          this.notificationService.create('error', 'Error', 'Login failed');
          this.loading.set(false);
        },
        complete: () => this.loading.set(false),
      });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  forgotPasswordClick(): void {
    const redirectUrl = 'https://dev.dxter.ai/api/auth/realms/Dev-Dexter/login-actions/reset-credentials';
    const params = new URLSearchParams({
      client_id: 'frontend',
      redirect_uri: 'dev.dxter.ai',
    });

    // Redirigir al usuario
    window.location.href = `${redirectUrl}?${params.toString()}`;
  }
}
