export * from './folders.service';
import { FoldersService } from './folders.service';
export * from './metadataFolders.service';
import { MetadataFoldersService } from './metadataFolders.service';
export * from './metadataOrganizations.service';
import { MetadataOrganizationsService } from './metadataOrganizations.service';
export * from './metadataUserGroups.service';
import { MetadataUserGroupsService } from './metadataUserGroups.service';
export * from './metadataUsers.service';
import { MetadataUsersService } from './metadataUsers.service';
export * from './metadataWorkspaces.service';
import { MetadataWorkspacesService } from './metadataWorkspaces.service';
export * from './organizationUsers.service';
import { OrganizationUsersService } from './organizationUsers.service';
export * from './organizations.service';
import { OrganizationsService } from './organizations.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userGroups.service';
import { UserGroupsService } from './userGroups.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './workspaces.service';
import { WorkspacesService } from './workspaces.service';
export const APIS = [FoldersService, MetadataFoldersService, MetadataOrganizationsService, MetadataUserGroupsService, MetadataUsersService, MetadataWorkspacesService, OrganizationUsersService, OrganizationsService, UserService, UserGroupsService, UsersService, WorkspacesService];
