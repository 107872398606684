import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { of, tap } from 'rxjs';
import { Organization, UserService, Workspace } from '../../../generated-code/role-manager-api';
import { Breadcrumb } from '../../core/models/breadcrumb.model';
import { LoadWorkspace } from '../admin/admin.actions';
import {
  LoadUserInfo,
  Logout,
  SetActiveTenant,
  SetActiveWorkspace,
  SetBreadcrumb,
  SetMenuCollapse,
  SetTenants,
} from './configuration.actions';
import { ConfigurationSelectors } from './configuration.selectors';

const STATE_NAME = 'configuration';
export class ConfigurationStateModel {
  isMenuCollapsed!: boolean;
  breadcrumb!: Breadcrumb[];
  user!: any;
  userToken!: any;
  activeTenant!: Organization | null;
  tenants!: Organization[];
  activeWorkspace!: Workspace | null;
}

@State<ConfigurationStateModel>({
  name: STATE_NAME,
  defaults: {
    isMenuCollapsed: true,
    breadcrumb: [],
    user: null,
    userToken: null,
    activeTenant: null,
    tenants: [],
    activeWorkspace: null,
  },
})
@Injectable()
export class ConfigurationState {
  userAPIService = inject(UserService);
  store = inject(Store);

  @Action(SetMenuCollapse)
  setFullScreen({ patchState }: StateContext<ConfigurationStateModel>, { payload }: SetMenuCollapse) {
    patchState({
      isMenuCollapsed: payload,
    });
  }

  @Action(SetBreadcrumb)
  setBreadcrumb({ patchState }: StateContext<ConfigurationStateModel>, { payload }: SetBreadcrumb) {
    patchState({
      breadcrumb: payload,
    });
  }

  @Action(LoadUserInfo)
  setUser({ patchState }: StateContext<ConfigurationStateModel>, { userToken }: LoadUserInfo) {
    return this.userAPIService.apiV1UserGet().pipe(
      tap((user) => {
        const activeTenant = user?.organization?.[0];
        const tenants = user?.organization || [];
        const activeWorkspace = user?.workspaces?.[0];
        patchState({ user, userToken, activeTenant, tenants, activeWorkspace });
      }),
    );
  }

  @Action(SetTenants)
  setTenants({ patchState }: StateContext<ConfigurationStateModel>, { tenants }: SetTenants) {
    patchState({
      tenants,
    });
  }

  @Action(SetActiveTenant)
  setActiveTenant({ patchState, dispatch }: StateContext<ConfigurationStateModel>, { tenant }: SetActiveTenant) {
    if (tenant) {
      patchState({ activeTenant: tenant });
      const workdpace = this.store.selectSnapshot(ConfigurationSelectors.activeWorkspace);
      if (workdpace?.organizationId !== tenant?.id) {
        return dispatch(new LoadWorkspace(tenant?.id as string));
      } else {
        return of(true);
      }
    } else {
      patchState({ activeTenant: null, activeWorkspace: null });
      return of(true);
    }
  }

  @Action(SetActiveWorkspace)
  setActiveWorkspace(
    { patchState, dispatch, getState }: StateContext<ConfigurationStateModel>,
    { workspace }: SetActiveWorkspace,
  ) {
    return patchState({ activeWorkspace: workspace });
  }

  @Action(Logout)
  logout({ patchState }: StateContext<ConfigurationStateModel>) {
    return patchState({ user: null });
  }
}
