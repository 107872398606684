/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DoeValObjetives = 'None' | 'Minimize' | 'Maximize';

export const DoeValObjetives = {
    None: 'None' as DoeValObjetives,
    Minimize: 'Minimize' as DoeValObjetives,
    Maximize: 'Maximize' as DoeValObjetives
};

