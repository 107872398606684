/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ColumnTypeValues = 'Undefined' | 'String' | 'Number' | 'Boolean' | 'Date';

export const ColumnTypeValues = {
    Undefined: 'Undefined' as ColumnTypeValues,
    String: 'String' as ColumnTypeValues,
    Number: 'Number' as ColumnTypeValues,
    Boolean: 'Boolean' as ColumnTypeValues,
    Date: 'Date' as ColumnTypeValues
};

