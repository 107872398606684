import { Injectable } from '@angular/core';
import {
  ColumnRoleValue,
  ColumnTypeValues,
  DoeInfoDto,
  ExperimentsDto,
  RecExpDto,
} from '../../../generated-code/logic-core-api';
import { ExperimentsRow } from '../../core/models/experiments-row.model';

@Injectable({
  providedIn: 'root',
})
export class ExperimentsHelperService {
  createRows(experiments: ExperimentsDto) {
    const rows: ExperimentsRow[] = [];
    for (const row of experiments.rows || []) {
      const expRowId = row?.id || '';
      const newRow: ExperimentsRow = { _expRowId: expRowId, nid: row.nid, notes: row.notes };
      for (const col of experiments.columns || []) {
        const expColId = col?.id || '';
        const value = experiments?.values?.find((v) => v.expColId === expColId && v?.expRowId === expRowId);
        if (value) {
          if (value.val === null) {
            newRow[expColId] = null;
          } else if (value?.val !== undefined && col.type === ColumnTypeValues.Number) {
            newRow[expColId] = +(value?.val as string);
          } else {
            newRow[expColId] = value.val;
          }
        }
      }
      rows.push(newRow);
    }
    return rows;
  }

  createRowsForRecomendations(experiments: RecExpDto) {
    const rows: ExperimentsRow[] = [];
    for (const row of experiments.rows || []) {
      const recExpRowId = row?.id || '';
      const newRow: ExperimentsRow = { _expRowId: recExpRowId };
      for (const col of experiments.columns || []) {
        const expColId = col?.id || '';
        const precission = col?.precision || 0;
        const value = experiments?.values?.find((v) => v.expColId === expColId && v?.recExpRowId === recExpRowId);
        if (value && value.val !== undefined && value.val !== null) {
          if (col.role === ColumnRoleValue.Response) {
            const std = Math.sqrt(value?.variance || 0);
            const inc = 1.96 * std;
            const min = +value.val - inc;
            const max = +value.val + inc;
            newRow[expColId] = {
              min: ExperimentsHelperService.formatNumber(min, precission),
              val: ExperimentsHelperService.formatNumber(+value.val, precission),
              max: ExperimentsHelperService.formatNumber(max, precission),
              inc: ExperimentsHelperService.formatNumber(inc, precission),
              std: ExperimentsHelperService.formatNumber(std, precission),
            };
          } else if (col.type === ColumnTypeValues.Number) {
            newRow[expColId] = { val: ExperimentsHelperService.formatNumber(+value.val, precission) };
          } else {
            newRow[expColId] = { val: value.val };
          }
        }
      }
      rows.push(newRow);
    }
    return rows;
  }

  static formatNumber(value: number | null, decimals: number) {
    if (value === null) return null;
    if (decimals === 0) return Math.round(value);
    const n = Math.pow(10, decimals);
    return Math.round(value * n) / n;
  }

  static formatNumberFixed(value: number | null, decimals: number) {
    if (value === null) return null;
    return value.toFixed(decimals);
  }

  static getExcludedEperiments(allData: ExperimentsRow[], doe: DoeInfoDto) {
    const usedColumns = [...(doe.factors || []), ...(doe.responses || [])];
    const rowsWithNulls = allData.filter((row) => {
      return usedColumns.some((col) => {
        const id = col?.column?.id as string;
        return row[id] === null || row[id] === undefined;
      });
    });

    const filters = doe.filters || [];
    const filteredRows = allData.filter((row) => {
      return (
        filters.length &&
        filters.every((filter) => {
          const id = filter?.column?.id as string;
          return row[id] !== filter.category;
        })
      );
    });

    console.log('rowsWithNulls', rowsWithNulls);
    console.log('filteredRows', filteredRows);

    return {
      rowsWithNulls,
      filteredRows,
    };
  }
}
