import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PortalsBridgeService {
  headerPortal = signal<TemplatePortal | null>(null);
  breadcrumbsPortal = signal<TemplatePortal | null>(null);
  footerPortal = signal<TemplatePortal | null>(null);
  rightPanelPortal = signal<TemplatePortal | null>(null);
  projectToolsPortal = signal<TemplatePortal | null>(null);
}
