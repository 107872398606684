/**
 * role-manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { User } from './user';
import { Folder } from './folder';
import { UserGroup } from './userGroup';
import { Workspace } from './workspace';


export interface UserInfoDto { 
    groups?: Array<UserGroup> | null;
    folders?: Array<Folder> | null;
    workspaces?: Array<Workspace> | null;
    organization?: Array<Organization> | null;
    user?: User;
}

