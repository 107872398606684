/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TaskSummary { 
    id?: string;
    recExpId?: string;
    status?: string | null;
    jobId?: string | null;
    jobName?: string | null;
    startedAt?: number | null;
    createdAt?: number | null;
    stoppedAt?: number | null;
    statusReason?: string | null;
}

