import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { DashboardDto, DashboardDtoItemsPaginated, DashboardService } from '../../../generated-code/logic-core-api';
import { Widget } from '../../core/models/widget.model';
import { ConfigurationSelectors } from '../configuration/configuration.selectors';
import { ProjectSelectors } from '../project/project.selectors';
import {
  AddWidget,
  CreateDashboard,
  DeleteDashboard,
  LoadDashboards,
  RemoveWidget,
  ResetVisualizationsState,
  SaveDashboard,
  SelectDashboard,
  SelectWidget,
  SetGridColumns,
  SetName,
  UpdateWidget,
} from './visualizations.actions';

const STATE_NAME = 'visualizations';
export class VisualizationsStateModel {
  loading!: boolean;
  error!: any | null;
  dashboards!: DashboardDto[];
  selectedDashboard!: DashboardDto | null;
  selectedWidget!: Widget | null;
}
@State<VisualizationsStateModel>({
  name: STATE_NAME,
  defaults: {
    loading: false,
    error: null,
    dashboards: [],
    selectedDashboard: null,
    selectedWidget: null,
  },
})
@Injectable()
export class VisualizationsState {
  store = inject(Store);
  dashboardService = inject(DashboardService);

  @Action(ResetVisualizationsState)
  resetProjectsState({ setState }: StateContext<VisualizationsStateModel>) {
    setState({
      loading: false,
      error: null,
      dashboards: [],
      selectedDashboard: null,
      selectedWidget: null,
    });
  }

  @Action(LoadDashboards)
  loadDashboards({ patchState, getState }: StateContext<VisualizationsStateModel>) {
    patchState({
      loading: true,
    });
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    patchState({
      loading: true,
    });
    return this.dashboardService.apiV1DashboardsProjectIdGet(project?.id as string, tenantId).pipe(
      tap((result: DashboardDtoItemsPaginated) => {
        const dashboards = result.items || (result as DashboardDto[]) || []; // FIXME: Type
        let selectedDashboard = getState().selectedDashboard;
        if (!selectedDashboard) {
          selectedDashboard = dashboards.find((dashboard) => dashboard.doeId === null) || null;
        } else {
          selectedDashboard = dashboards.find((dashboard) => dashboard.id === selectedDashboard?.id) || dashboards[0];
        }
        patchState({
          dashboards: dashboards,
          selectedDashboard,
          loading: false,
        });
      }),
    );
  }

  @Action(CreateDashboard)
  createDashboard({ dispatch, getState }: StateContext<VisualizationsStateModel>, { dashboard }: CreateDashboard) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    return this.dashboardService.apiV1DashboardsProjectIdPost(project?.id as string, tenantId, dashboard).pipe(
      tap(() => {
        dispatch(new LoadDashboards());
      }),
    );
  }

  @Action(DeleteDashboard)
  deleteDashboard({ dispatch, patchState }: StateContext<VisualizationsStateModel>, { dashboard }: DeleteDashboard) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    const projectId = project?.id as string;
    const dashboardId = dashboard.id as string;
    return this.dashboardService.apiV1DashboardsProjectIdDashboardIdDelete(projectId, dashboardId, tenantId).pipe(
      tap(() => {
        patchState({
          selectedDashboard: null,
        });
        dispatch(new LoadDashboards());
      }),
    );
  }

  @Action(SaveDashboard)
  saveDashboard({ dispatch, getState }: StateContext<VisualizationsStateModel>) {
    const dashboard = getState().selectedDashboard as DashboardDto;
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    const projectId = project?.id as string;
    const dashboardId = dashboard.id as string;

    const widgets = dashboard?.widgets?.map(({ filters, ...widget }: any) => widget);

    const updatedDashboard = { ...dashboard, widgets };

    return this.dashboardService
      .apiV1DashboardsProjectIdDashboardIdPut(projectId, dashboardId, tenantId, updatedDashboard)
      .pipe(
        tap(() => {
          //dispatch(new LoadDashboards());
        }),
      );
  }

  @Action(SelectDashboard)
  selectDashboard({ patchState }: StateContext<VisualizationsStateModel>, { dashboard }: SelectDashboard) {
    return patchState({
      selectedDashboard: dashboard,
    });
  }

  @Action(AddWidget)
  addWidget({ patchState, getState, dispatch }: StateContext<VisualizationsStateModel>, { widget }: AddWidget) {
    if (getState().selectedDashboard) {
      const selectedDashboard = getState().selectedDashboard as DashboardDto;
      const widgets = selectedDashboard?.widgets || [];
      selectedDashboard.widgets = [...widgets, widget];
      patchState({
        selectedDashboard: { ...selectedDashboard },
      });
      dispatch(new SaveDashboard());
    }
  }

  @Action(RemoveWidget)
  removeWidget({ patchState, getState, dispatch }: StateContext<VisualizationsStateModel>, { widget }: RemoveWidget) {
    const selectedDashboard = getState().selectedDashboard as DashboardDto;
    const widgets = selectedDashboard?.widgets || [];
    selectedDashboard.widgets = widgets.filter((w) => w.widgetId !== widget.widgetId);
    patchState({
      selectedDashboard: { ...selectedDashboard },
    });
    dispatch(new SaveDashboard());
  }

  @Action(SelectWidget)
  selectWidget({ patchState }: StateContext<VisualizationsStateModel>, { widget }: SelectWidget) {
    patchState({
      selectedWidget: widget,
    });
  }

  @Action(UpdateWidget)
  updateWidget({ patchState, getState, dispatch }: StateContext<VisualizationsStateModel>, { widget }: UpdateWidget) {
    const dashboard = getState().selectedDashboard;
    if (dashboard) {
      const index = dashboard.widgets?.findIndex((w) => w.widgetId === widget.widgetId);
      const widgets = [...(getState().selectedDashboard?.widgets || [])];

      if (index !== undefined) {
        const oldWidgetWithoutFilters: any = this.cleanWidget(widgets[index]);
        const newWidgetWiotuhFilters: any = this.cleanWidget(widget);

        widgets[index] = widget;
        patchState({
          selectedDashboard: {
            ...dashboard,
            widgets,
          },
        });
        8;

        if (!this.areEquals(oldWidgetWithoutFilters, newWidgetWiotuhFilters)) {
          dispatch(new SaveDashboard());
        }
      }
    }
  }

  private areEquals(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private cleanWidget(widget: any) {
    const { filters, ...widgetWithoutFilters } = widget;
    for (const key in widgetWithoutFilters) {
      if (widgetWithoutFilters[key] === null || widgetWithoutFilters[key] === undefined) {
        delete widgetWithoutFilters[key];
      }
    }
    return widgetWithoutFilters;
  }

  @Action(SetGridColumns)
  setGridColumns(
    { patchState, getState, dispatch }: StateContext<VisualizationsStateModel>,
    { gridColumns }: SetGridColumns,
  ) {
    const selectedDashboard = getState().selectedDashboard as DashboardDto;
    if (selectedDashboard.numColumns !== gridColumns) {
      selectedDashboard.numColumns = gridColumns;
      patchState({
        selectedDashboard: { ...selectedDashboard },
      });
      dispatch(new SaveDashboard());
    }
  }

  @Action(SetName)
  setName({ patchState, getState, dispatch }: StateContext<VisualizationsStateModel>, { name }: SetName) {
    const selectedDashboard = getState().selectedDashboard as DashboardDto;
    selectedDashboard.name = name;
    patchState({
      selectedDashboard: { ...selectedDashboard },
    });
    dispatch(new SaveDashboard());
  }
}
