import { Organization, Workspace } from '../../../generated-code/role-manager-api';
import { Breadcrumb } from '../../core/models/breadcrumb.model';

export class SetMenuCollapse {
  static readonly type = '[UI] Set Menu Collapse';
  constructor(public payload: boolean) {}
}

export class SetBreadcrumb {
  static readonly type = '[UI] Set Breadcrumb';
  constructor(public payload: Breadcrumb[]) {}
}

export class SetTenants {
  static readonly type = '[UI] Set Tenants';
  constructor(public tenants: Organization[]) {}
}

export class SetActiveTenant {
  static readonly type = '[UI] Set ActiveTenant';
  constructor(public tenant: Organization | null) {}
}

export class SetActiveWorkspace {
  static readonly type = '[UI] Set WorkspaceId';
  constructor(public workspace: Workspace | null) {}
}

export class LoadUserInfo {
  static readonly type = '[UI] Load User Info';
  constructor(public userToken: any) {}
}

export class Logout {
  static readonly type = '[UI] Logout';
}
