/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MockProjectsValues = 'Antirreflectante' | 'Farmaceutica';

export const MockProjectsValues = {
    Antirreflectante: 'Antirreflectante' as MockProjectsValues,
    Farmaceutica: 'Farmaceutica' as MockProjectsValues
};

