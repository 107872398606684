import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { ProjectState, ProjectStateModel } from './project.state';

@Injectable({ providedIn: 'root' })
export class ProjectSelectors {
  @Selector([ProjectState])
  static loadingProjects(state: ProjectStateModel) {
    return state.loadingProjects;
  }

  @Selector([ProjectState])
  static loadingProject(state: ProjectStateModel) {
    return state.loadingProject;
  }

  @Selector([ProjectState])
  static error(state: ProjectStateModel) {
    return state.errorProjects;
  }

  @Selector([ProjectState])
  static projects(state: ProjectStateModel) {
    return state.projects;
  }

  @Selector([ProjectState])
  static errorProjects(state: ProjectStateModel) {
    return state.errorProjects;
  }

  @Selector([ProjectState])
  static selectedProject(state: ProjectStateModel) {
    return state.selectedProject;
  }

  @Selector([ProjectState])
  static tabs(state: ProjectStateModel) {
    return state.tabs;
  }

  @Selector([ProjectState])
  static selectedIndex(state: ProjectStateModel) {
    return state.selectedIndex;
  }

  @Selector([ProjectState])
  static newProject(state: ProjectStateModel) {
    return state.newProject;
  }
}
