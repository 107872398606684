/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ColumnRoleValue = 'Undefined' | 'Factor' | 'Response';

export const ColumnRoleValue = {
    Undefined: 'Undefined' as ColumnRoleValue,
    Factor: 'Factor' as ColumnRoleValue,
    Response: 'Response' as ColumnRoleValue
};

