/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WidgetDto { 
    name?: string | null;
    x?: string | null;
    y?: string | null;
    y2?: string | null;
    z?: string | null;
    series?: string | null;
    type?: string | null;
    widgetId?: string | null;
    corrMatMaximizeCol1?: boolean | null;
    corrMatMaximizeCol2?: boolean | null;
}

