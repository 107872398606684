import { Project } from '../../../generated-code/logic-core-api';
import { Folder } from '../../../generated-code/role-manager-api';

import { Tab } from '../../core/models/tab.model';

export class ResetProjectsState {
  static readonly type = '[PROJECT] Reset Projects';
}

export class LoadProjects {
  static readonly type = '[PROJECT] Load Projects';
  constructor(public workspaceId: string) {}
}

export class SelectProject {
  static readonly type = '[PROJECT] Select Project';
  constructor(public project: Project | null) {}
}

export class LoadProjectById {
  static readonly type = '[PROJECT] Load Project by id';
  constructor(
    public workspaceId: string,
    public projectId: string,
  ) {}
}

export class InitProjectTabs {
  static readonly type = '[PROJECT] Init Tabs';
}

export class InitNewProjectTabs {
  static readonly type = '[PROJECT] Init Tabs for New Project';
}

export class SelectProjectTab {
  static readonly type = '[PROJECT] Select Tab';
  constructor(public index: number) {}
}

export class AddProjectTab {
  static readonly type = '[PROJECT] Add Tab';
  constructor(public tab: Tab) {}
}

export class CloseProjectTabByIndex {
  static readonly type = '[PROJECT] Close tab by index';
  constructor(
    public index: number,
    public moveToIndex: number,
  ) {}
}

export class RenameProjectTab {
  static readonly type = '[PROJECT] Rename Tab';
  constructor(
    public dataId: string,
    public label: string,
  ) {}
}

export class CreateProject {
  static readonly type = '[PROJECT] Create Project';
  constructor(
    public workspaceId: string,
    public project: Project,
  ) {}
}

export class UpdateProject {
  static readonly type = '[PROJECT] Update Project';
  constructor(
    public workspaceId: string,
    public project: Project,
  ) {}
}

export class DeleteProject {
  static readonly type = '[PROJECT] Delete Project';
  constructor(
    public workspaceId: string,
    public project: Project,
  ) {}
}

export class CreateFolder {
  static readonly type = '[PROJECT] Create Folder';
  constructor(
    public workspaceId: string,
    public folder: Folder,
  ) {}
}

export class UpdateFolder {
  static readonly type = '[PROJECT] Update Folder';
  constructor(
    public workspaceId: string,
    public folder: Folder,
  ) {}
}

export class DeleteFolder {
  static readonly type = '[PROJECT] Delete Folder';
  constructor(
    public workspaceId: string,
    public folder: Folder,
  ) {}
}
