/**
 * role-manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserState } from './userState';
import { UserRole } from './userRole';


export interface UserUpdateAdminDto { 
    firstName?: string | null;
    lastName?: string | null;
    role?: UserRole;
    state?: UserState;
}
export namespace UserUpdateAdminDto {
}


