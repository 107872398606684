/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Stats { 
    id?: string;
    expColId?: string;
    q1?: number;
    q2?: number;
    q3?: number;
    q4?: number;
    mean?: number;
    _var?: number;
    mode?: number;
}

