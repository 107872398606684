import { inject, Injectable } from '@angular/core';
import { Action, Actions, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import { catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { Project, ProjectsService } from '../../../generated-code/logic-core-api';
import { FoldersService } from '../../../generated-code/role-manager-api';
import { Tab } from '../../core/models/tab.model';
import { LoadFolders } from '../admin/admin.actions';
import { SetActiveTenant } from '../configuration/configuration.actions';
import { ConfigurationSelectors } from '../configuration/configuration.selectors';
import { LoadDoesByProjectId } from '../does/does.actions';
import { LoadExperimentsByProjectId } from '../experiments/experiments.actions';
import { Undoable } from '../plugins/ngxs-history-plugin';
import { LoadDashboards } from '../visualizations/visualizations.actions';
import {
  AddProjectTab,
  CloseProjectTabByIndex,
  CreateFolder,
  CreateProject,
  DeleteFolder,
  DeleteProject,
  InitNewProjectTabs,
  InitProjectTabs,
  LoadProjectById,
  LoadProjects,
  RenameProjectTab,
  ResetProjectsState,
  SelectProject,
  SelectProjectTab,
  UpdateFolder,
  UpdateProject,
} from './project.actions';

const STATE_NAME = 'project';
export class ProjectStateModel {
  loadingProjects!: boolean;
  errorProjects!: any | null;
  projects!: Project[];
  loadingProject!: boolean;
  selectedProject!: Project | null;
  tabs!: Tab[];
  selectedIndex!: number;
  newProject!: Project | null;
}
@State<ProjectStateModel>({
  name: STATE_NAME,
  defaults: {
    loadingProjects: false,
    errorProjects: null,
    loadingProject: false,
    projects: [],
    selectedProject: null,
    tabs: [],
    selectedIndex: 0,
    newProject: null,
  },
})
@Injectable()
export class ProjectState {
  projectsAPIService = inject(ProjectsService);
  folderAPIService = inject(FoldersService);
  store = inject(Store);
  actions$ = inject(Actions);

  @Action(ResetProjectsState)
  resetProjectsState({ patchState }: StateContext<ProjectStateModel>) {
    patchState({
      loadingProjects: false,
      errorProjects: null,
      projects: [],
      loadingProject: false,
      selectedProject: null,
      tabs: [],
      selectedIndex: 0,
      newProject: null,
    });
  }

  @Action(LoadProjects)
  @Undoable(STATE_NAME, LoadProjects)
  loadProjects({ patchState }: StateContext<ProjectStateModel>, { workspaceId }: LoadProjects) {
    patchState({
      loadingProjects: true,
    });
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const filters: any = { tenant: tenantId };
    return this.projectsAPIService.apiV1ProjectsWorkspaceIdGet(workspaceId, filters).pipe(
      tap(({ items }) => {
        patchState({
          loadingProjects: false,
          projects: items || [],
          errorProjects: null,
        });
      }),
      catchError((error) => {
        patchState({
          loadingProjects: false,
          projects: [],
          errorProjects: error,
        });
        return error;
      }),
      takeUntil(this.actions$.pipe(ofActionDispatched(SetActiveTenant))),
    );
  }

  @Action(SelectProject)
  selectProject({ patchState }: StateContext<ProjectStateModel>, { project }: SelectProject) {
    patchState({
      selectedProject: project,
    });
  }

  @Action(LoadProjectById)
  loadProject({ patchState, dispatch }: StateContext<ProjectStateModel>, { projectId, workspaceId }: LoadProjectById) {
    patchState({
      loadingProject: true,
    });
    if (projectId) {
      const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
      return this.projectsAPIService.apiV1ProjectsWorkspaceIdIdGet(workspaceId, projectId, tenantId).pipe(
        tap((project) => {
          patchState({
            loadingProject: false,
            selectedProject: project,
            newProject: null,
          });
          dispatch(new LoadExperimentsByProjectId(projectId));
          dispatch(new LoadDoesByProjectId(projectId));
          dispatch(new LoadDashboards());
        }),
        catchError((error) => {
          patchState({
            loadingProject: false,
            selectedProject: null,
            errorProjects: error,
          });
          return error;
        }),
      );
    } else {
      patchState({
        loadingProject: false,
        selectedProject: {},
      });
    }
    return of();
  }

  @Action(InitProjectTabs)
  initTabs({ patchState }: StateContext<ProjectStateModel>) {
    patchState({
      tabs: [
        { type: 'dataset', label: 'Experiments' },
        { type: 'visualizations', label: 'Data Analysis' },
        { type: 'does', label: 'DOEs' },
      ],
    });
  }

  @Action(InitNewProjectTabs)
  initTabsForNewProject({ patchState }: StateContext<ProjectStateModel>) {
    patchState({
      tabs: [{ type: 'metadata', label: 'Project' }],
    });
  }

  @Action(SelectProjectTab)
  selectTab({ patchState }: StateContext<ProjectStateModel>, { index }: SelectProjectTab) {
    patchState({
      selectedIndex: index,
    });
  }

  @Action(AddProjectTab)
  @Undoable(STATE_NAME, AddProjectTab)
  addTab({ patchState, getState, dispatch }: StateContext<ProjectStateModel>, { tab }: AddProjectTab) {
    const state = getState();
    const tabs = [...state.tabs, tab];
    patchState({
      tabs,
    });
    dispatch(new SelectProjectTab(tabs.length - 1));
  }

  @Action(CloseProjectTabByIndex)
  @Undoable(STATE_NAME, CloseProjectTabByIndex)
  closeTab({ patchState, getState }: StateContext<ProjectStateModel>, { index, moveToIndex }: CloseProjectTabByIndex) {
    const state = getState();
    const tabs = state.tabs.filter((tab: Tab, i: number) => i !== index);
    patchState({
      tabs,
      selectedIndex: moveToIndex,
    });
  }

  @Action(RenameProjectTab)
  renameTab({ patchState, getState }: StateContext<ProjectStateModel>, { dataId, label }: RenameProjectTab) {
    const state = getState();
    const tabs = [...state.tabs];
    const index = tabs.findIndex((tab: Tab, i: number) => tab?.data?.id === dataId);
    if (index !== -1) {
      tabs[index] = { ...tabs[index], label };
      patchState({
        tabs,
      });
    }
  }

  @Action(CreateFolder)
  createFolder({ dispatch, getState }: StateContext<ProjectStateModel>, { workspaceId, folder }: CreateFolder) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.folderAPIService.apiV1AdminWorkspacesWorkspaceIdFoldersPost(workspaceId, tenantId, folder).pipe(
      tap(() => {
        const activeTenant = this.store.selectSnapshot(ConfigurationSelectors.activeTenant);
        dispatch(new LoadFolders(activeTenant?.id as string, folder.workspaceId || null));
      }),
    );
  }

  @Action(UpdateFolder)
  updateFolder({ dispatch, getState }: StateContext<ProjectStateModel>, { workspaceId, folder }: UpdateFolder) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.folderAPIService
      .apiV1AdminWorkspacesWorkspaceIdFoldersIdPut(workspaceId, folder.id || '', tenantId, folder)
      .pipe(
        tap(() => {
          const activeTenant = this.store.selectSnapshot(ConfigurationSelectors.activeTenant);
          dispatch(new LoadFolders(activeTenant?.id as string, folder.workspaceId || null));
        }),
      );
  }

  @Action(DeleteFolder)
  deleteFolder({ dispatch, getState }: StateContext<ProjectStateModel>, { workspaceId, folder }: DeleteFolder) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.folderAPIService
      .apiV1AdminWorkspacesWorkspaceIdFoldersIdDelete(workspaceId, folder.id || '', tenantId)
      .pipe(
        tap(() => {
          const activeTenant = this.store.selectSnapshot(ConfigurationSelectors.activeTenant);
          dispatch(new LoadFolders(activeTenant?.id as string, folder.workspaceId || null));
        }),
      );
  }

  @Action(CreateProject)
  createProject({ dispatch, patchState }: StateContext<ProjectStateModel>, { workspaceId, project }: CreateProject) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.projectsAPIService.apiV1ProjectsWorkspaceIdPost(workspaceId, tenantId, project).pipe(
      tap((project) => {
        patchState({
          newProject: project,
        });
        dispatch(new LoadProjectById(workspaceId, project.id || ''));
      }),
    );
  }

  @Action(UpdateProject)
  updateProject(
    { dispatch, patchState, getState }: StateContext<ProjectStateModel>,
    { workspaceId, project }: UpdateProject,
  ) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.projectsAPIService
      .apiV1ProjectsWorkspaceIdIdPut(workspaceId, project?.id || '', tenantId, project)
      .pipe(
        switchMap(() => dispatch(new LoadProjectById(workspaceId, project.id || ''))),
        tap(() => {
          const projects = getState().projects;
          const index = projects.findIndex((p) => p.id === project.id);
          if (index !== -1) {
            projects[index] = getState().selectedProject as Project;
          }
          patchState({
            projects: [...projects],
          });
        }),
      );
  }

  @Action(DeleteProject)
  deleteProject({ dispatch, getState }: StateContext<ProjectStateModel>, { workspaceId, project }: DeleteProject) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.projectsAPIService
      .apiV1ProjectsWorkspaceIdIdDelete(workspaceId, project.id || '', tenantId)
      .pipe(switchMap(() => dispatch(new LoadProjects(workspaceId))));
  }
}
