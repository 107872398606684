/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DoeConstraintOperators } from './doeConstraintOperators';
import { ExpCol } from './expCol';


export interface DoeConstraintDto { 
    name?: string | null;
    value?: number;
    columns?: Array<ExpCol> | null;
    equalityOperator?: DoeConstraintOperators;
}
export namespace DoeConstraintDto {
}


