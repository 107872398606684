/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FuncAdquisitionValues = 'GM' | 'QEHVI' | 'QEI';

export const FuncAdquisitionValues = {
    Gm: 'GM' as FuncAdquisitionValues,
    Qehvi: 'QEHVI' as FuncAdquisitionValues,
    Qei: 'QEI' as FuncAdquisitionValues
};

