/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExpRow } from './expRow';
import { ExpVal } from './expVal';
import { ExpCol } from './expCol';


export interface ExperimentsDto { 
    columns?: Array<ExpCol> | null;
    rows?: Array<ExpRow> | null;
    values?: Array<ExpVal> | null;
}

