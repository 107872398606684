/**
 * role-manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MetadataOrganization { 
    id?: string;
    attributeName?: string | null;
    attributeValue?: string | null;
    timestamp?: string;
    lastEditorId?: string;
    entityId?: string;
}

