import { ExpCol } from '../../../generated-code/logic-core-api';

export class ResetExperimentsState {
  static readonly type = '[EXPERIMENTS] Reset';
}

export class LoadExperimentsByProjectId {
  static readonly type = '[EXPERIMENTS] Load Dataset by project id';
  constructor(public projectId: string) {}
}

export class CreateExperimentsFromScratch {
  static readonly type = '[EXPERIMENTS] Create Experiments from scratch';
}

export class AddRow {
  static readonly type = '[EXPERIMENTS] Add Row to Experiments';
}

export class UploadData {
  static readonly type = '[EXPERIMENTS] Upload Data';
  constructor(
    public projectId: string,
    public file: File,
    public type: 'csv' | 'excel' | 'json',
    public separator?: string,
  ) {}
}

export class RemoveRow {
  static readonly type = '[EXPERIMENTS] Remove Row to Experiments';
  constructor(public rowId: string) {}
}

export class UpdateRow {
  static readonly type = '[EXPERIMENTS] Update Row';
  constructor(
    public rowId: string,
    public notes: string,
  ) {}
}

export class UpdateValue {
  static readonly type = '[EXPERIMENTS] Update Value';
  constructor(
    public rowId: string,
    public colId: string,
    public value: any,
  ) {}
}

export class AddConcept {
  static readonly type = '[EXPERIMENTS] Add Concept';
  constructor(
    public projectId: string,
    public concept: ExpCol,
  ) {}
}

export class DeleteConcept {
  static readonly type = '[EXPERIMENTS] Delete Concept';
  constructor(
    public projectId: string,
    public concept: ExpCol,
  ) {}
}

export class UpdateConcept {
  static readonly type = '[EXPERIMENTS] Update Concept';
  constructor(
    public projectId: string,
    public concept: ExpCol,
  ) {}
}

export class UpdateRowMetadata {
  static readonly type = '[EXPERIMENTS] Update Row Metadata';
  constructor(
    public projectId: string,
    public rowId: string,
    public name: string,
    public notes: string,
  ) {}
}

export class LoadStats {
  static readonly type = '[EXPERIMENTS] UpdLoad Stats';
  constructor(
    public projectId: string,
    public colId: string,
  ) {}
}

export class ResetStats {
  static readonly type = '[EXPERIMENTS] Reset Stats';
}

export class HideColumn {
  static readonly type = '[EXPERIMENTS] Hide Column';
  constructor(public colId: string) {}
}
