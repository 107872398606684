import { DashboardDto, WidgetDto } from '../../../generated-code/logic-core-api';
import { Widget } from '../../core/models/widget.model';

export class ResetVisualizationsState {
  static readonly type = '[VISUALIZATIONS] Reset';
}

export class LoadDashboards {
  static readonly type = '[VISUALIZATIONS] Load Dashboards';
}

export class CreateDashboard {
  static readonly type = '[VISUALIZATIONS] Create Dashboard';
  constructor(public dashboard: DashboardDto) {}
}

export class DeleteDashboard {
  static readonly type = '[VISUALIZATIONS] Delete Dashboard';
  constructor(public dashboard: DashboardDto) {}
}

export class SaveDashboard {
  static readonly type = '[VISUALIZATIONS] Save Dashboard';
  constructor() {}
}

export class SelectDashboard {
  static readonly type = '[VISUALIZATIONS] Select Dashboard';
  constructor(public dashboard: DashboardDto) {}
}

export class AddWidget {
  static readonly type = '[VISUALIZATIONS] Add Widget';
  constructor(
    public widget: WidgetDto, //FIXME: Type
  ) {}
}

export class RemoveWidget {
  static readonly type = '[VISUALIZATIONS] Remove Widget';
  constructor(
    public widget: Widget, //FIXME: Type
  ) {}
}

export class SelectWidget {
  static readonly type = '[VISUALIZATIONS] Select Widget';
  constructor(
    public widget: Widget | null, //FIXME: Type
  ) {}
}

export class UpdateWidget {
  static readonly type = '[VISUALIZATIONS] Update Widget';
  constructor(
    public widget: any, //FIXME: Type
  ) {}
}

export class SetGridColumns {
  static readonly type = '[VISUALIZATIONS] Set Grid Columns';
  constructor(public gridColumns: number) {}
}

export class SetName {
  static readonly type = '[VISUALIZATIONS] Set Name';
  constructor(public name: string) {}
}
