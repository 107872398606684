/**
 * role-manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserGroup } from './userGroup';


export interface Folder { 
    id?: string;
    name?: string | null;
    workspaceId?: string;
    parentFolderId?: string | null;
    groups?: Array<UserGroup> | null;
}

