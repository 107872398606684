/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DoeValObjetives } from './doeValObjetives';
import { ExpCol } from './expCol';


export interface DoeValDto { 
    column?: ExpCol;
    direction?: DoeValObjetives;
    min?: number | null;
    max?: number | null;
    constant?: boolean | null;
}
export namespace DoeValDto {
}


