import { Tag, TagCreateDto } from '../../../generated-code/logic-core-api';
import { Organization, User, UserGroup, Workspace } from '../../../generated-code/role-manager-api';
import { Tab } from '../../core/models/tab.model';

export class ResetAdminState {
  static readonly type = '[ADMIN] Reset';
}

export class LoadOrganizations {
  static readonly type = '[ADMIN] Load organizations';
}

export class LoadWorkspace {
  static readonly type = '[ADMIN] Load Workspace by organization id';
  constructor(public organizationId: string | null) {}
}

export class LoadUsers {
  static readonly type = '[ADMIN] Load Users by organization id';
  constructor(public organizationId: string | null) {}
}

export class LoadGroups {
  static readonly type = '[ADMIN] Load Groups by organization id';
  constructor(public organizationId: string | null) {}
}

export class LoadFolders {
  static readonly type = '[ADMIN] Load Folders by workdpace id';
  constructor(
    public organizationId: string | null,
    public workspaceId: string | null,
  ) {}
}

export class LoadTags {
  static readonly type = '[ADMIN] Load tags by workspace id';
  constructor(
    public organizationId: string | null,
    public workspaceId: string | null,
  ) {}
}

export class CreateTag {
  static readonly type = '[ADMIN] Create new tag';
  constructor(
    public organizationId: string,
    public tag: TagCreateDto,
  ) {}
}

export class UpdateTag {
  static readonly type = '[ADMIN] Update tag';
  constructor(
    public organizationId: string,
    public tagId: string,
    public tag: TagCreateDto,
  ) {}
}

export class DeleteTag {
  static readonly type = '[ADMIN] Delete tag';
  constructor(
    public organizationId: string,
    public tag: Tag,
  ) {}
}

export class SelectOrganizationById {
  static readonly type = '[ADMIN] Select organization by id';
  constructor(public id: string | null) {}
}

export class GetOrganizationById {
  static readonly type = '[ADMIN] Get Organization by Id';
  constructor(public organizationId: string) {}
}

export class CreateOrganization {
  static readonly type = '[ADMIN] Create Organization';
  constructor(public organization: Organization) {}
}

export class UpdateOrganization {
  static readonly type = '[ADMIN] Update Organization';
  constructor(public organization: Organization) {}
}

export class DeleteOrganization {
  static readonly type = '[ADMIN] Delete Organization';
  constructor(public organizationId: string) {}
}

export class CreateWorkspace {
  static readonly type = '[ADMIN] Create Workspace';
  constructor(public workspace: Workspace) {}
}

export class UpdateWorkspace {
  static readonly type = '[ADMIN] Update Workspace';
  constructor(public workspace: Workspace) {}
}

export class DeleteWorkspace {
  static readonly type = '[ADMIN] Delete Workspace';
  constructor(public workspace: Workspace) {}
}

export class InitTabs {
  static readonly type = '[ADMIN] Init tabs';
}

export class CloseTabByIndex {
  static readonly type = '[ADMIN] Close tab by index';
  constructor(public index: number) {}
}

export class AddTab {
  static readonly type = '[ADMIN] Add tab';
  constructor(public tab: Tab) {}
}

export class SelectTab {
  static readonly type = '[ADMIN] Select tab';
  constructor(public index: number) {}
}

export class CreateGroup {
  static readonly type = '[ADMIN] Create Group';
  constructor(public group: UserGroup) {}
}

export class UpdateGroup {
  static readonly type = '[ADMIN] Update Group';
  constructor(public group: UserGroup) {}
}

export class DeleteGroup {
  static readonly type = '[ADMIN] Delete Group';
  constructor(public group: UserGroup) {}
}

export class CreateUser {
  static readonly type = '[ADMIN] Create User';
  constructor(public user: User) {}
}

export class UpdateUser {
  static readonly type = '[ADMIN] Update User';
  constructor(public user: User) {}
}

export class DeleteUser {
  static readonly type = '[ADMIN] Delete User';
  constructor(public user: User) {}
}

export class AsignUserToGroup {
  static readonly type = '[ADMIN] Asign User To Group';
  constructor(
    public groupId: string,
    public userId: string,
  ) {}
}

export class RemoveUserFromGroup {
  static readonly type = '[ADMIN] Remove User From Group';
  constructor(
    public groupId: string,
    public userId: string,
  ) {}
}
