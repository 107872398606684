<nz-layout class="app-layout">
  <!--<nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    [nzCollapsed]="isCollapsed()"
    (nzCollapsedChange)="changeCollapse($event)"
    [nzTrigger]="null">
    <app-nav-menu></app-nav-menu>
  </nz-sider>-->
  <nz-layout class="w-100">
    <app-header></app-header>
    <div class="overflow-hidden h-100">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </nz-layout>
</nz-layout>
<hotkeys-cheatsheet title="Shortcuts"></hotkeys-cheatsheet>
