export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './doe.service';
import { DoeService } from './doe.service';
export * from './experimentCell.service';
import { ExperimentCellService } from './experimentCell.service';
export * from './experimentColumnStats.service';
import { ExperimentColumnStatsService } from './experimentColumnStats.service';
export * from './experimentColumns.service';
import { ExperimentColumnsService } from './experimentColumns.service';
export * from './experimentRows.service';
import { ExperimentRowsService } from './experimentRows.service';
export * from './experiments.service';
import { ExperimentsService } from './experiments.service';
export * from './exportData.service';
import { ExportDataService } from './exportData.service';
export * from './graphs.service';
import { GraphsService } from './graphs.service';
export * from './importData.service';
import { ImportDataService } from './importData.service';
export * from './mockProject.service';
import { MockProjectService } from './mockProject.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './recomendations.service';
import { RecomendationsService } from './recomendations.service';
export * from './tags.service';
import { TagsService } from './tags.service';
export const APIS = [DashboardService, DoeService, ExperimentCellService, ExperimentColumnStatsService, ExperimentColumnsService, ExperimentRowsService, ExperimentsService, ExportDataService, GraphsService, ImportDataService, MockProjectService, ProjectsService, RecomendationsService, TagsService];
