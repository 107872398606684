import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs';
import { Tag, TagsService } from '../../../generated-code/logic-core-api';
import {
  Folder,
  FoldersService,
  Organization,
  OrganizationsService,
  OrganizationUsersService,
  User,
  UserGroup,
  UserGroupsService,
  UserIam,
  UserRegisterDto,
  UserRole,
  UsersService,
  UserState,
  UserUpdateAdminDto,
  Workspace,
  WorkspacesService,
} from '../../../generated-code/role-manager-api';
import { Tab } from '../../core/models/tab.model';
import { SetActiveTenant, SetActiveWorkspace, SetTenants } from '../configuration/configuration.actions';
import { ConfigurationSelectors } from '../configuration/configuration.selectors';
import { Undoable } from '../plugins/ngxs-history-plugin';
import {
  AddTab,
  AsignUserToGroup,
  CloseTabByIndex,
  CreateGroup,
  CreateOrganization,
  CreateTag,
  CreateUser,
  CreateWorkspace,
  DeleteGroup,
  DeleteOrganization,
  DeleteTag,
  DeleteUser,
  DeleteWorkspace,
  InitTabs,
  LoadFolders,
  LoadGroups,
  LoadOrganizations,
  LoadTags,
  LoadUsers,
  LoadWorkspace,
  RemoveUserFromGroup,
  ResetAdminState,
  SelectOrganizationById,
  SelectTab,
  UpdateGroup,
  UpdateOrganization,
  UpdateTag,
  UpdateUser,
  UpdateWorkspace,
} from './admin.actions';

export class AdminStateModel {
  loadingOrganizations!: boolean;
  loadingSelectedOrganization!: boolean;
  loadingWorkspaces!: boolean;
  loadingUsers!: boolean;
  loadingGroups!: boolean;
  loadingFolders!: boolean;
  loadingTags!: boolean;
  organizations!: Organization[];
  selectedOrganization!: Organization | null;
  workspace!: Workspace | null;
  users!: User[];
  groups!: UserGroup[];
  folders!: Folder[];
  tags!: Tag[];
  tabs!: Tab[];
  selectedIndex!: number;
  newUser!: User | null;
}

const STATE_NAME = 'admin';
@State<AdminStateModel>({
  name: STATE_NAME,
  defaults: {
    loadingOrganizations: false,
    loadingSelectedOrganization: false,
    loadingWorkspaces: false,
    loadingUsers: false,
    loadingGroups: false,
    loadingFolders: false,
    loadingTags: false,
    organizations: [],
    selectedOrganization: null,
    workspace: null,
    users: [],
    groups: [],
    folders: [],
    tags: [],
    tabs: [],
    selectedIndex: 0,
    newUser: null,
  },
})
@Injectable()
export class AdminState {
  organizationAPIService = inject(OrganizationsService);
  organizationUsersAPIService = inject(OrganizationUsersService);
  userAPIService = inject(UsersService);
  groupAPIService = inject(UserGroupsService);
  folderAPIService = inject(FoldersService);
  workspaceAPIService = inject(WorkspacesService);
  tagsAPIService = inject(TagsService);
  store = inject(Store);

  @Action(ResetAdminState)
  reset({ setState }: StateContext<ResetAdminState>) {
    setState({
      loadingOrganizations: false,
      loadingSelectedOrganization: false,
      loadingWorkspaces: false,
      loadingUsers: false,
      loadingGroups: false,
      loadingFolders: false,
      loadingTags: false,
      organizations: [],
      selectedOrganization: null,
      workspace: null,
      users: [],
      groups: [],
      folders: [],
      tags: [],
      tabs: [],
      selectedIndex: 0,
    });
  }

  @Action(LoadOrganizations)
  loadOrganizations({ patchState, dispatch }: StateContext<AdminStateModel>) {
    patchState({
      loadingOrganizations: true,
    });
    return this.organizationAPIService.apiV1SuperadminOrganizationsGet().pipe(
      tap({
        next: ({ items }) => {
          const organizations = items || undefined;
          patchState({
            loadingOrganizations: false,
            organizations,
          });
          const organization = this.store.selectSnapshot(ConfigurationSelectors.activeTenant);
          const workspace = this.store.selectSnapshot(ConfigurationSelectors.activeWorkspace);
          if (organization && workspace) {
            //dispatch(new LoadTags(organization?.id as string, workspace?.id as string));
          }
          dispatch(new SetTenants(organizations || []));
        },
      }),
    );
  }

  @Action(CreateOrganization)
  createOrganization({ patchState, dispatch }: StateContext<AdminStateModel>, { organization }: CreateOrganization) {
    return this.organizationAPIService.apiV1SuperadminOrganizationsPost(organization).pipe(
      tap({
        next: (organization) => {
          patchState({
            selectedOrganization: organization,
          });
          dispatch(new LoadOrganizations());
        },
      }),
    );
  }

  @Action(UpdateOrganization)
  updateOrganization({ dispatch }: StateContext<AdminStateModel>, { organization }: UpdateOrganization) {
    if (!organization.id) return;
    return this.organizationAPIService.apiV1AdminOrganizationsPut(organization.id, organization).pipe(
      tap({
        next: () => {
          dispatch(new LoadOrganizations());
        },
      }),
    );
  }

  @Action(DeleteOrganization)
  deleteOrganization({ patchState, dispatch }: StateContext<AdminStateModel>, { organizationId }: DeleteOrganization) {
    return this.organizationAPIService.apiV1SuperadminOrganizationsIdDelete(organizationId).pipe(
      tap({
        next: () => {
          patchState({
            selectedOrganization: null,
          });
          dispatch(new LoadOrganizations());
          dispatch(new SetActiveTenant(null));
        },
      }),
    );
  }

  @Action(SelectOrganizationById)
  selectOrganizationById(
    { getState, patchState, dispatch }: StateContext<AdminStateModel>,
    { id }: SelectOrganizationById,
  ) {
    const state = getState();
    if (id) {
      patchState({
        loadingSelectedOrganization: true,
      });
      return this.organizationAPIService.apiV1AdminOrganizationsIdGet(id, id).pipe(
        tap({
          next: (organization) => {
            patchState({
              loadingSelectedOrganization: false,
              selectedOrganization: organization,
            });
            //Load workspace from server
            dispatch(new LoadWorkspace(id));
            //Load users from server
            dispatch(new LoadUsers(id));
            //Load groups from server
            dispatch(new LoadGroups(id));
            dispatch(new SetActiveTenant(organization));
          },
        }),
      );
    } else {
      return patchState({
        selectedOrganization: null,
        workspace: null,
        users: [],
        groups: [],
        folders: [],
      });
    }
  }

  @Action(LoadWorkspace)
  loadWorkspace({ dispatch, patchState }: StateContext<AdminStateModel>, { organizationId }: LoadWorkspace) {
    //Go to server
    patchState({
      loadingWorkspaces: true,
    });
    if (organizationId) {
      const filters = { organizationId, tenant: organizationId };
      return this.workspaceAPIService.apiV1AdminWorkspacesGet(undefined, undefined, filters).pipe(
        tap({
          next: ({ items }) => {
            const workspace = items?.[0] || null;
            patchState({
              loadingWorkspaces: false,
              workspace,
              folders: [],
              tags: [],
            });
            if (workspace) {
              dispatch(new LoadTags(workspace.organizationId as string, workspace?.id || null));
              dispatch(new SetActiveWorkspace(workspace));
            } else {
              dispatch(new SetActiveWorkspace(null));
            }
          },
        }),
      );
    } else {
      return patchState({
        loadingWorkspaces: false,
        workspace: null,
      });
    }
  }

  @Action(LoadUsers)
  loadUsers({ dispatch, patchState }: StateContext<AdminStateModel>, { organizationId }: LoadUsers) {
    //Go to server
    patchState({
      loadingUsers: true,
    });
    if (organizationId) {
      const filters = { organizationId, tenant: organizationId };
      return this.userAPIService.apiV1AdminUsersGet(undefined, undefined, filters).pipe(
        tap({
          next: ({ items }) => {
            const users = items || undefined;
            patchState({
              loadingUsers: false,
              users,
              newUser: null,
            });
          },
        }),
      );
    } else {
      return patchState({
        loadingUsers: false,
        users: [],
      });
    }
  }

  @Action(LoadGroups)
  loadGroups({ dispatch, patchState }: StateContext<AdminStateModel>, { organizationId }: LoadGroups) {
    //Go to server
    patchState({
      loadingGroups: true,
    });
    if (organizationId) {
      const filters = { tenant: organizationId, organizationId };
      return this.groupAPIService.apiV1AdminUserGroupsGet(undefined, undefined, filters).pipe(
        tap({
          next: ({ items }) => {
            const groups = items || undefined;
            patchState({
              loadingUsers: false,
              groups,
            });
          },
        }),
      );
    } else {
      return patchState({
        loadingUsers: false,
        groups: [],
      });
    }
  }

  @Action(LoadFolders)
  loadFolders({ dispatch, patchState }: StateContext<AdminStateModel>, { organizationId, workspaceId }: LoadFolders) {
    //Go to server
    patchState({
      loadingFolders: true,
    });
    if (workspaceId) {
      const filters = { tenant: organizationId || '' };
      return this.folderAPIService
        .apiV1AdminWorkspacesWorkspaceIdFoldersGet(workspaceId, undefined, undefined, filters)
        .pipe(
          tap({
            next: ({ items }) => {
              const folders = items || undefined;
              patchState({
                loadingFolders: false,
                folders,
              });
            },
          }),
        );
    } else {
      return patchState({
        loadingFolders: false,
        folders: [],
      });
    }
  }

  @Action(LoadTags)
  loadTags({ dispatch, patchState }: StateContext<AdminStateModel>, { organizationId, workspaceId }: LoadTags) {
    //Go to server
    patchState({
      loadingTags: true,
    });
    if (workspaceId) {
      const filters = { tenant: organizationId || '', workspaceId };
      return this.tagsAPIService.apiV1TagsGet(filters, undefined, undefined).pipe(
        tap({
          next: ({ items }) => {
            const tags = items || undefined;
            patchState({
              loadingTags: false,
              tags,
            });
          },
        }),
      );
    } else {
      return patchState({
        loadingTags: false,
        folders: [],
      });
    }
  }

  @Action(CreateTag)
  createTag({ dispatch }: StateContext<AdminStateModel>, { organizationId, tag }: CreateTag) {
    return this.tagsAPIService
      .apiV1TagsPost(organizationId, tag)
      .pipe(switchMap(() => dispatch(new LoadTags(organizationId, tag.workspaceId as string))));
  }

  @Action(UpdateTag)
  updateTag({ dispatch }: StateContext<AdminStateModel>, { organizationId, tagId, tag }: UpdateTag) {
    return this.tagsAPIService
      .apiV1TagsIdPut(tagId, organizationId, tag)
      .pipe(switchMap(() => dispatch(new LoadTags(organizationId, tag.workspaceId as string))));
  }

  @Action(DeleteTag)
  deleteTag({ dispatch }: StateContext<AdminStateModel>, { organizationId, tag }: DeleteTag) {
    return this.tagsAPIService
      .apiV1TagsIdDelete(tag.id as string, organizationId)
      .pipe(switchMap(() => dispatch(new LoadTags(organizationId, tag.workspaceId as string))));
  }

  @Action(CreateWorkspace)
  createWorkspace({ patchState, dispatch }: StateContext<AdminStateModel>, { workspace }: CreateWorkspace) {
    return this.workspaceAPIService.apiV1AdminWorkspacesPost(workspace.organizationId, workspace).pipe(
      tap({
        next: (organization) => {
          patchState({
            workspace,
          });
          dispatch(new LoadWorkspace(workspace.organizationId || null));
        },
      }),
    );
  }

  @Action(UpdateWorkspace)
  updateWorkspace({ patchState }: StateContext<AdminStateModel>, { workspace }: UpdateWorkspace) {
    if (!workspace.id) return;
    return this.workspaceAPIService.apiV1AdminWorkspacesIdPut(workspace.id, workspace.organizationId, workspace).pipe(
      tap({
        next: () => {
          patchState({
            workspace,
          });
        },
      }),
    );
  }

  @Action(DeleteWorkspace)
  deleteWorkspace({ patchState, dispatch }: StateContext<AdminStateModel>, { workspace }: DeleteWorkspace) {
    patchState({
      loadingWorkspaces: true,
    });
    return this.workspaceAPIService.apiV1AdminWorkspacesIdDelete(workspace.id as string, workspace.organizationId).pipe(
      tap({
        next: () => {
          patchState({
            loadingWorkspaces: false,
            workspace: null,
          });
        },
      }),
    );
  }

  @Action(InitTabs)
  initTabs({ patchState }: StateContext<AdminStateModel>) {
    patchState({
      tabs: [
        { type: 'metadata', label: 'Organization' },
        { type: 'users', label: 'Users / Groups' },
        //{ type: 'folders', label: 'Folders' },
      ],
    });
  }

  @Action(CloseTabByIndex)
  @Undoable(STATE_NAME, CloseTabByIndex)
  closeTab({ patchState, getState }: StateContext<AdminStateModel>, { index }: CloseTabByIndex) {
    const state = getState();
    const tabs = state.tabs.filter((tab: Tab, i: number) => i !== index);
    patchState({
      tabs,
      selectedIndex: 1,
    });
  }

  @Action(AddTab)
  @Undoable(STATE_NAME, AddTab)
  addTab({ patchState, getState, dispatch }: StateContext<AdminStateModel>, { tab }: AddTab) {
    const state = getState();
    const tabs = [...state.tabs, tab];
    patchState({
      tabs,
    });
    dispatch(new SelectTab(tabs.length - 1));
  }

  @Action(SelectTab)
  selectTab({ patchState }: StateContext<AdminStateModel>, { index }: SelectTab) {
    patchState({
      selectedIndex: index,
    });
  }

  @Action(CreateGroup)
  createGroup({ dispatch }: StateContext<AdminStateModel>, { group }: CreateGroup) {
    return this.groupAPIService.apiV1AdminUserGroupsPost(group.organizationId, group).pipe(
      tap({
        next: (newGroup) => {
          dispatch(new LoadGroups(newGroup.organizationId || null));
        },
      }),
    );
  }

  @Action(UpdateGroup)
  updateGroup({ dispatch }: StateContext<AdminStateModel>, { group }: UpdateGroup) {
    return this.groupAPIService.apiV1AdminUserGroupsIdPut(group?.id || '', group.organizationId, group).pipe(
      tap({
        next: () => {
          dispatch(new LoadGroups(group.organizationId || null));
        },
      }),
    );
  }

  @Action(DeleteGroup)
  deleteGroup({ dispatch }: StateContext<AdminStateModel>, { group }: DeleteGroup) {
    return this.groupAPIService
      .apiV1AdminUserGroupsIdDelete(group?.id || '', group.organizationId)
      .pipe(switchMap(() => dispatch(new LoadGroups(group.organizationId || null))));
  }

  @Action(CreateUser)
  createUser({ dispatch, patchState }: StateContext<AdminStateModel>, { user }: CreateUser) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const userIam: UserIam = {
      username: user.username,
      lastName: user.lastName,
      email: user.email,
      firstName: user.firstName,
      enable: user.state === 'Active',
    };
    const userRegister: UserRegisterDto = {
      user: userIam,
      organizationId: user.organizationId,
    };
    if (user.role == 'Admin') {
      const organizationId = user.organizationId as string;
      return this.organizationUsersAPIService
        .apiV1SuperadminUsersOrganizationIdAdminsPost(organizationId, userRegister)
        .pipe(
          tap({
            next: (newUser) => {
              patchState({
                newUser: newUser || null,
              });
              dispatch(new LoadUsers(newUser.organizationId || null));
            },
          }),
        );
    } else {
      return this.userAPIService.apiV1AdminUsersPost(tenantId, userRegister).pipe(
        tap({
          next: (newUser) => {
            patchState({
              newUser: newUser || null,
            });
            dispatch(new LoadUsers(newUser.organizationId || null));
          },
        }),
      );
    }
  }

  @Action(UpdateUser)
  updateUser({ dispatch }: StateContext<AdminStateModel>, { user }: UpdateUser) {
    const { firstName, lastName, email, role, state } = user;
    const userUpdated: UserUpdateAdminDto = {
      firstName,
      lastName,
      role: role as UserRole,
      state: state as UserState,
    };
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.userAPIService.apiV1AdminUsersUserIdPut(user?.id || '', tenantId, userUpdated).pipe(
      tap({
        next: () => {
          dispatch(new LoadUsers(user.organizationId || null));
        },
      }),
    );
  }

  @Action(DeleteUser)
  deleteUser({ dispatch }: StateContext<AdminStateModel>, { user }: DeleteUser) {
    return this.userAPIService
      .apiV1AdminUsersUserIdDelete(user?.id || '', user.organizationId)
      .pipe(switchMap(() => dispatch(new LoadUsers(user.organizationId || null))));
  }

  @Action(AsignUserToGroup)
  asignUserToGroup({ dispatch, getState }: StateContext<AdminStateModel>, { userId, groupId }: AsignUserToGroup) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.groupAPIService.apiV1AdminUserGroupsIdUsersUserIdPost(groupId, userId, tenantId).pipe(
      tap(() => {
        const organizationId = getState().selectedOrganization?.id;
        dispatch(new LoadGroups(organizationId as string));
      }),
    );
  }

  @Action(RemoveUserFromGroup)
  removeUserToGroup({ dispatch, getState }: StateContext<AdminStateModel>, { userId, groupId }: AsignUserToGroup) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.groupAPIService.apiV1AdminUserGroupsIdUsersUserIdDelete(groupId, userId, tenantId).pipe(
      tap(() => {
        const organizationId = getState().selectedOrganization?.id;
        dispatch(new LoadGroups(organizationId as string));
      }),
    );
  }
}
