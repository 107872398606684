import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { ConfigurationState, ConfigurationStateModel } from './configuration.state';

@Injectable({ providedIn: 'root' })
export class ConfigurationSelectors {
  @Selector([ConfigurationState])
  static isMenuCollapsed(state: ConfigurationStateModel) {
    return state.isMenuCollapsed;
  }

  @Selector([ConfigurationState])
  static breadcrumb(state: ConfigurationStateModel) {
    return state.breadcrumb;
  }

  @Selector([ConfigurationState])
  static user(state: ConfigurationStateModel) {
    return state.user;
  }

  @Selector([ConfigurationState])
  static isAdmin(state: ConfigurationStateModel) {
    return state.userToken?.groups?.includes('Admins');
  }

  @Selector([ConfigurationState])
  static isSuperAdmin(state: ConfigurationStateModel) {
    return state.userToken?.groups?.includes('SuperAdmins');
  }

  @Selector([ConfigurationState])
  static folders(state: ConfigurationStateModel) {
    return state.user?.folders || [];
  }

  @Selector([ConfigurationState])
  static activeWorkspace(state: ConfigurationStateModel) {
    return state.activeWorkspace;
  }

  @Selector([ConfigurationState])
  static activeWorkspaceId(state: ConfigurationStateModel) {
    return state.activeWorkspace?.id || '';
  }

  @Selector([ConfigurationState])
  static tenants(state: ConfigurationStateModel) {
    return state.tenants;
  }

  @Selector([ConfigurationState])
  static activeTenant(state: ConfigurationStateModel) {
    return state.activeTenant;
  }
}
