/**
 * role-manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserState = 'Active' | 'Deleted';

export const UserState = {
    Active: 'Active' as UserState,
    Deleted: 'Deleted' as UserState
};

