/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DoeConstraintOperators = 'Equals' | 'LessThan' | 'LessThanOrEqual' | 'GreaterThan' | 'GreaterThanOrEqual';

export const DoeConstraintOperators = {
    Equals: 'Equals' as DoeConstraintOperators,
    LessThan: 'LessThan' as DoeConstraintOperators,
    LessThanOrEqual: 'LessThanOrEqual' as DoeConstraintOperators,
    GreaterThan: 'GreaterThan' as DoeConstraintOperators,
    GreaterThanOrEqual: 'GreaterThanOrEqual' as DoeConstraintOperators
};

