/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExpCol { 
    id?: string;
    createdAt?: number;
    updatedAt?: number;
    author?: string | null;
    notes?: string | null;
    projectId?: string;
    statsId?: string | null;
    name?: string | null;
    role?: string | null;
    type?: string | null;
    precision?: number;
}

