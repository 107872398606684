/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DoeFilterDto } from './doeFilterDto';
import { DoeConstraintDto } from './doeConstraintDto';
import { DoeValDto } from './doeValDto';
import { Doe } from './doe';


export interface DoeInfoDto { 
    doe?: Doe;
    factors?: Array<DoeValDto> | null;
    responses?: Array<DoeValDto> | null;
    constraints?: Array<DoeConstraintDto> | null;
    filters?: Array<DoeFilterDto> | null;
}

