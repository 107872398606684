import { Doe, DoeCreateDto, DoeInfoDto } from '../../../generated-code/logic-core-api';

export class LoadDoesByProjectId {
  static readonly type = '[DOES] Load DOEs by project id';
  constructor(public projectId: string) {}
}

export class LoadDoeById {
  static readonly type = '[DOES] Load DOE by id';
  constructor(
    public projectId: string,
    public doeId: string,
  ) {}
}

export class UnloadDoeById {
  static readonly type = '[DOES] Unload DOE by id';
  constructor(public doeId: string) {}
}

export class CreateDoe {
  static readonly type = '[DOES] Create DOE';
  constructor(
    public projectId: string,
    public newDoe: DoeCreateDto,
  ) {}
}

export class DeleteDoe {
  static readonly type = '[DOES] Delete DOE';
  constructor(
    public projectId: string,
    public doe: Doe,
  ) {}
}

export class UpdateDoe {
  static readonly type = '[DOES] Update DOE';
  constructor(
    public projectId: string,
    public doe: DoeInfoDto,
  ) {}
}

export class SaveDoeChanges {
  static readonly type = '[DOES] Save DOE Changes';
  constructor(public doe: DoeInfoDto) {}
}

export class CloneDoe {
  static readonly type = '[DOES] Clone DOE';
  constructor(
    public projectId: string,
    public doe: DoeInfoDto,
  ) {}
}
export class LoadStatuses {
  static readonly type = '[DOES] Load DOE Statuses';
  constructor(
    public projectId: string,
    public doeId: string,
  ) {}
}

export class CreateRecomendation {
  static readonly type = '[DOES] Create Recommendation';
  constructor(
    public projectId: string,
    public doe: DoeInfoDto,
    public nRecomentations: number,
  ) {}
}
